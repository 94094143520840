import { useSelector } from 'react-redux';
import { AuthUserSelector } from '../state/authUser';
import { SpaceViewSelector } from '../state/spaceView';
import { usePluginHost } from './usePluginHost';
import { createSpacePermission } from '../api/space';
import {
  disableRecipientVerification,
  enableRecipientVerification,
} from '../api/backdrop';

import * as Icon from 'react-feather';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import InputGroup from 'react-bootstrap/InputGroup';
import { useReducer, useState } from 'react';
import { useActions } from './useActions';
import { WizardAction } from '../state/wizard';
import endsWith from 'lodash/endsWith';

function StepComplete(props) {
  const { recipients, collaborators } = props;
  const [recipient, ...otherRecipientEmails] = recipients;
  const allEmails = [...new Set([...otherRecipientEmails, ...collaborators])];

  const orgDomainName = useSelector(AuthUserSelector.getOrgDomainName);
  const allCollaborators = allEmails.filter((email) =>
    endsWith(email, `@${orgDomainName}`)
  );
  const otherRecipients = allEmails.filter(
    (email) => !endsWith(email, `@${orgDomainName}`)
  );

  const hasCollaborators = allCollaborators.length > 0;
  const numCollaborators = allCollaborators.length;
  const collaboratorText =
    numCollaborators > 1 ? `${numCollaborators} team members` : 'a team member';

  const hasOtherRecipients = otherRecipients.length > 0;

  const allMembers = useSelector(SpaceViewSelector.getTeamMembers);
  const pendingMembers = allCollaborators.filter((email) => {
    const member = allMembers.find((m) => m.email === email);
    if (!member) {
      // this collaborator does not exists
      return true;
    }

    return !member.isVerified || Boolean(member.isInvitePending);
  });
  const licensedMembers = allMembers.filter(
    (member) =>
      member.isVerified &&
      !member.isInvitePending &&
      allCollaborators.includes(member.email) &&
      !pendingMembers.includes(member.email)
  );
  const numPendingMembers = pendingMembers.length;
  const hasPendingMembers = numPendingMembers > 0;

  const viewerUrl = useSelector(AuthUserSelector.getViewerUrl);
  const backdrop = useSelector(SpaceViewSelector.getSelectedBackdrop);
  const url = `${viewerUrl}/${backdrop?.code}`;
  const previewUrl = `${viewerUrl}/preview/${backdrop?.code}`;

  const [checked, setChecked] = useState(
    !backdrop?.disableRecipientVerification
  );

  const handleRecipientVerificationChange = async (e) => {
    const isEnabled = e.target.checked;
    setChecked(isEnabled);
    if (isEnabled) {
      await enableRecipientVerification(backdrop.code);
    } else {
      await disableRecipientVerification(backdrop.code);
    }
  };

  const [hasInserted, markAsInserted] = useReducer(() => true, false);

  const { insertUrlInMailBody } = usePluginHost();

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const handleInsert = async () => {
    setProcessing(true);
    setError('');

    try {
      // 1. add collaborators if needed
      const tasks = licensedMembers.map((member) =>
        createSpacePermission(backdrop.space._id, {
          user: member._id,
        })
      );

      await Promise.all(tasks);

      // 2. insert into email body
      await insertUrlInMailBody(backdrop.code, backdrop.space.title, url);
      markAsInserted();
    } catch (ex) {
      console.log('cannot add as collaborator');
      console.error(ex);
      setError(ex.message);
    } finally {
      setProcessing(false);
    }
  };

  const goToStepBackdrop = useActions(WizardAction.goToStepBackdrop);
  const goHome = useActions(WizardAction.goToStepHome);

  return (
    <div className="content">
      <div className="complete-screen">
        <header className="mg-t-10 mg-b-20">
          <h1 className="tx-light tx-26">Your backdrop is ready.</h1>
        </header>
      </div>
      <main>
        <p>
          You're sending <b>{backdrop.space?.title}</b> to <i>{recipient}</i>{' '}
          {hasCollaborators && <span>and {collaboratorText}</span>}
        </p>
        {hasPendingMembers && (
          <div className="pd-0">
            <Alert variant="danger">
              Warning: The following member(s) of your team are not licensed or
              activated yet. <br />
              <ul className="pd-l-30 mg-0">
                {pendingMembers.map((email) => (
                  <li>{email}</li>
                ))}
              </ul>
              <br />
              They might accidently open the backdrop link.{' '}
              <a
                href="https://help.backdrop.io/en/articles/6025324-outlook-plugin-multiple-recipients"
                target="_blank"
              >
                Learn more...
              </a>
            </Alert>
          </div>
        )}
        {hasOtherRecipients && (
          <div className="pd-0">
            <Alert variant="danger">
              <p>
                Warning: Backdrops have a unique link for each recipient.
                Sending to multiple recipients may prompt your customer to
                verify their email address when accessing the Backdrop.{' '}
                <a
                  href="https://help.backdrop.io/en/articles/6025324-outlook-plugin-multiple-recipients"
                  target="_blank"
                >
                  Learn more...
                </a>
              </p>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="recipient-verification"
                  value={checked}
                  checked={checked}
                  onChange={handleRecipientVerificationChange}
                />
                <label
                  className="custom-control-label"
                  for="recipient-verification"
                >
                  Recipient Verification is {checked ? 'ON' : 'OFF'}
                </label>
              </div>
            </Alert>
          </div>
        )}

        <InputGroup>
          <Form.Control type="text" data-cy="public_url" value={url} readOnly />
          <InputGroup.Append>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => <Tooltip {...props}>Preview</Tooltip>}
            >
              <Button
                variant="outline-primary"
                href={previewUrl}
                target="_blank"
              >
                <Icon.ExternalLink />
              </Button>
            </OverlayTrigger>
          </InputGroup.Append>
        </InputGroup>

        {error && (
          <Alert variant="danger" className="mg-t-10">
            {error}
          </Alert>
        )}

        {hasInserted ? (
          <div className="mg-t-25 animate__animated animate__fadeIn animate__delay-1s">
            <Button variant="outline-primary" block onClick={goToStepBackdrop}>
              Continue Editing
            </Button>

            <div className="divider-text">or</div>

            <Button variant="outline-secondary" block onClick={goHome}>
              <span>Select Another Backdrop</span>
            </Button>
          </div>
        ) : (
          <div className="mg-t-25">
            <Button
              variant="brand-02"
              block
              disabled={processing}
              onClick={handleInsert}
            >
              {processing ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span>Insert Into Email</span>
              )}
            </Button>
          </div>
        )}
      </main>
    </div>
  );
}

export default StepComplete;
