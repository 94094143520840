import { useCallback, useState } from 'react';
import api from '../api';
import { updateSpaceTemplate } from '../api/space';
import * as Icon from 'react-feather';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

import get from 'lodash/get';
import path from 'lodash/fp/path';
import chunk from 'lodash/chunk';

import { useSelector } from 'react-redux';
import { AuthUserSelector } from '../state/authUser';
import { SpaceViewAction, SpaceViewSelector } from '../state/spaceView';
import { WizardAction } from '../state/wizard';
import { useActions } from './useActions';

// import useUploadOutlookAttachments from './useUploadOutlookAttachments';

import { getStorate } from './universalStorage';
import useDialogState from './useDialogState';
import useBackdropUppy from './useBackdropUppy';
import PostItem from './PostItem';
import UploadModal from './UploadModal';
import DraftPlaceholderView from './DraftPlaceholderView';
import SpaceBackgroundUploader from './SpaceBackgroundUploader';
import useApi from './useApi';
import { archiveSpace } from '../api/space';
import { NotificationAction } from '../state/notification';
import SyncSubjectButton from './SyncSubjectButton';
import RenameSpaceButton from './RenameSpaceButton';
import emailToName from 'email-to-name';
import DocumentItem from './DocumentItem';
import SaveDefaultTemplateButton from './SaveDefaultTemplateButton';

function getPostRows(filteredPosts = [], pinnedPostId = null) {
  if (!pinnedPostId) {
    return chunk(filteredPosts, 2);
  }

  const pinnedPost = filteredPosts.find((post) => post._id === pinnedPostId);
  if (!pinnedPost) {
    return chunk(filteredPosts, 2);
  }

  const normalPosts = filteredPosts.filter((post) => post._id !== pinnedPostId);
  const reorderedPosts = [pinnedPost, ...normalPosts];
  return chunk(reorderedPosts, 2);
}

function SpaceContent({ space, recipient }) {
  const goToStepHome = useActions(WizardAction.goToStepHome);
  const goToStepComplete = useActions(WizardAction.goToStepComplete);
  const selectInvite = useActions(SpaceViewAction.selectInvite);

  const spaceId = space._id;
  const pinnedPostId = space.pinnedPostId;
  const posts = space.posts || [];
  const filteredPosts = posts.filter((post) => !get(post, 'media[0].removed'));
  const mediaIds = filteredPosts.map(path('media[0]._id'));
  const hasContent = mediaIds.length > 0;

  const rows = getPostRows(filteredPosts, pinnedPostId);

  // upload
  const [showUploadModal, openUploadModal, closeUploadModal] =
    useDialogState(false);

  const uppy = useBackdropUppy({
    spaceId,
    closeModal: closeUploadModal,
  });

  const handleOpenUploadModal = () => {
    uppy.reset();
    openUploadModal();
  };

  // handle playbook preview
  const [playbookDocuments, setPlaybookDocuments] = useState([]);
  const [fetchingPlaybookContent, setFetchingPlaybookContent] = useState(false);
  const hasPlaybookContent = playbookDocuments.length > 0;

  const playbookDocumentRows = chunk(playbookDocuments, 2);

  // selected document ids
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);

  // playbooks
  const playbooks = useSelector(SpaceViewSelector.getSortedPlaybooks);
  const [currentPlaybook, setCurrentPlaybook] = useState(null);
  const handlePlaybookChange = async (e) => {
    const id = e.target.value;
    if (id === '-') {
      setCurrentPlaybook(null);
      setPlaybookDocuments([]);
    } else {
      const selected = playbooks.find((item) => item._id === e.target.value);
      setCurrentPlaybook(selected);
      setFetchingPlaybookContent(true);

      // playbook content
      const resp = await api.service('media').find({
        query: {
          tags: selected.name,
          type: { $in: ['file', 'integration', 'url'] },
          $limit: 10000,
          $sort: { createdAt: 1 },
        },
      });

      const docs = resp.data || [];
      setSelectedDocumentIds(docs.map((doc) => doc._id));
      setPlaybookDocuments(docs);
      setFetchingPlaybookContent(false);
    }
  };

  const handleDocumentSelection = (docId) => {
    if (selectedDocumentIds.includes(docId)) {
      setSelectedDocumentIds(selectedDocumentIds.filter((id) => id !== docId));
    } else {
      setSelectedDocumentIds([...selectedDocumentIds, docId]);
    }
  };

  // attachment
  const onAttachmentAdded = useCallback(async (uppy, attachment, blob) => {
    if (!uppy) {
      return;
    }
    if (!showUploadModal) {
      // uppy.reset();
      openUploadModal();
    }

    try {
      uppy.addFile({
        name: attachment.name,
        type: attachment.contentType,
        data: blob,
        source: 'react:DashboardModal',
        isRemote: false,
        meta: {
          outlookAttachment: attachment,
        },
      });
    } catch (err) {
      console.log('cannot add file');
    }
  }, []);
  // useUploadOutlookAttachments(uppy, {
  //   onAttachmentAdded,
  // });

  // templates
  const templates = useSelector(SpaceViewSelector.getTemplates);
  const handleTemplateChange = async (e) => {
    try {
      await updateSpaceTemplate(spaceId, e.target.value);
    } catch (error) {
      showNotification({
        title: 'Error',
        body: 'An error occurred. Please try again.',
      });
      console.log(error);
    }
  };
  const activeTemplateId = space.draftTemplate?._id
    ? space.draftTemplate._id
    : '';

  // handleContinue
  const [saving, setSaving] = useState(false);
  const showNotification = useActions(NotificationAction.show);
  const handleContinue = async () => {
    if (!space || !spaceId) {
      showNotification({
        title: 'Error',
        body: 'Fatal error: no spaces found.',
      });
      return;
    }

    if (!hasContent && selectedDocumentIds.length === 0) {
      showNotification({
        title: 'Error',
        body: 'Please add some files to the backdrop.',
      });
      return;
    }

    setSaving(true);
    try {
      // 1. create contact if needed
      let contact;
      const resp = await api.service('contacts').find({
        query: { email: recipient, $limit: 1 },
      });
      if (resp.data?.length > 0) {
        contact = resp.data[0];
      } else {
        contact = await api.service('contacts').create({
          name: emailToName.process(recipient),
          email: recipient,
        });
      }

      // 2. update space
      if (currentPlaybook) {
        const docs = playbookDocuments.filter((doc) =>
          selectedDocumentIds.includes(doc._id)
        );

        if (docs.length > 0) {
          // user posts
          const postIds = space.posts.map((p) => p._id);

          // playbook posts
          const newPosts = await Promise.all(
            docs.map((file) =>
              api.service('posts').create({
                media: [file._id],
              })
            )
          );
          const newPostIds = newPosts.map((p) => p._id);

          // merged posts
          const nextPostIds = [...postIds, ...newPostIds];

          await api.service('spaces').patch(spaceId, {
            status: 'published',
            posts: nextPostIds,
          });
        } else {
          await api.service('spaces').patch(spaceId, {
            status: 'published',
          });
        }
      } else {
        await api.service('spaces').patch(spaceId, {
          status: 'published',
        });
      }

      // 3. create space invite
      const inviteData = {
        space: space._id,
        contact: contact._id,
        $autosend: false,
        visibility: 'everyone',
        recipientsCanUpload: true,
      };
      if (space.draftTemplate?._id) {
        inviteData.template = space.draftTemplate._id;
      }
      const invite = await api.service('space-invites').create(inviteData);

      // 4. update space again
      await api.service('spaces').patch(space._id, {
        invites: [invite._id],
      });

      console.log('create invite', invite);

      // 5. fetch the backdrop again
      const backdrop = await api.service('space-invites').get(invite.code);

      selectInvite(backdrop);

      const storage = getStorate();
      storage.removeItem('draftSpaceId');
      setDraftSpace(null);
      setSaving(false);

      goToStepComplete();
    } catch (error) {
      showNotification({
        title: 'Error',
        body: error.message,
      });
      setSaving(false);
    }

    goToStepComplete();
  };

  // handle removing backdrop
  const setDraftSpace = useActions(SpaceViewAction.setDraftSpace);
  const removeDraftSpace = useCallback(
    async (spaceId) => {
      await archiveSpace(spaceId);
      const storage = getStorate();
      storage.removeItem('draftSpaceId');
      setDraftSpace(null);
      goToStepHome();
    },
    [goToStepHome]
  );

  const [{ loading: removing }, handleRemove] = useApi(removeDraftSpace, {
    args: [spaceId],
  });

  const org = useSelector(AuthUserSelector.getOrganization);
  const showTemplateSelect = Boolean(org?.features?.plugin_can_select_template);
  const currentMode = useSelector(SpaceViewSelector.getCurrentMode);
  const shouldShowSyncButton = currentMode === 'compose';

  return (
    <div>
      <div className="pd-15">
        <InputGroup>
          <Form.Control type="text" readOnly value={space.title} />
          <InputGroup.Append>
            <RenameSpaceButton space={space} />
            {shouldShowSyncButton && <SyncSubjectButton spaceId={spaceId} />}
          </InputGroup.Append>
        </InputGroup>
      </div>

      {showTemplateSelect && (
        <div className="pd-b-15 pd-x-15">
          <InputGroup>
            <select
              className="custom-select"
              value={activeTemplateId}
              onChange={handleTemplateChange}
            >
              <option value="">No Template</option>
              {templates.map((template) => (
                <option key={template._id} value={template._id}>
                  {template.name}
                </option>
              ))}
            </select>
            <InputGroup.Append>
              <SaveDefaultTemplateButton templateId={activeTemplateId} />
            </InputGroup.Append>
          </InputGroup>
        </div>
      )}

      <div className="pd-x-15 mg-b-20">
        <select
          className="custom-select"
          value={currentPlaybook ? currentPlaybook._id : '-'}
          onChange={handlePlaybookChange}
        >
          <option key="-" value="-">
            Start Blank (default)
          </option>
          {playbooks.map((item) => (
            <option key={item._id} value={item._id}>
              Start from "{item.name}"
            </option>
          ))}
        </select>
      </div>

      <div className="pd-x-8 pos-relative">
        {fetchingPlaybookContent && (
          <div className="pos-absolute t-5 r-15">
            <Spinner
              as="span"
              animation="border"
              variant="primary"
              role="status"
              size="sm"
              aria-hidden="true"
            />
          </div>
        )}
        <SpaceBackgroundUploader
          uppy={uppy}
          onDrop={handleOpenUploadModal}
          hasTemplateSelect={showTemplateSelect}
        >
          {hasContent ? (
            <div className="pd-x-6">
              {rows.map((row, index) => (
                <div className="row row-xs justify-content-start" key={index}>
                  {row.map((post) => (
                    <div
                      className="col-6"
                      key={post._id}
                      style={{ padding: 8 }}
                    >
                      <PostItem
                        spaceId={spaceId}
                        post={post}
                        editable
                        pinned={pinnedPostId === post._id}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ) : (
            <DraftPlaceholderView
              canBrowse
              onBrowse={handleOpenUploadModal}
              minimize={hasPlaybookContent}
            />
          )}

          {hasPlaybookContent && (
            <div className="pd-x-6 pd-t-10">
              <label className="flex-fill d-block tx-medium tx-10 tx-uppercase tx-sans tx-spacing-1 tx-color-03 pd-l-5 mg-0">
                Playbook Content
              </label>
              <hr className="mg-5" />
              {playbookDocumentRows.map((row, index) => (
                <div className="row row-xs justify-content-start" key={index}>
                  {row.map((doc) => (
                    <div className="col-6" key={doc._id} style={{ padding: 8 }}>
                      <DocumentItem
                        file={doc}
                        editable={false}
                        selected={selectedDocumentIds.includes(doc._id)}
                        onChange={handleDocumentSelection}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </SpaceBackgroundUploader>
      </div>

      <div className="app-sticky-footer">
        <div className="pd-x-15 pd-b-30 pd-t-30 d-flex justify-content-between">
          {removing ? (
            <Button variant="link" className="tx-danger pd-0">
              <Spinner
                as="span"
                variant="danger"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </Button>
          ) : (
            <Button
              variant="link"
              className="tx-danger pd-0"
              onClick={handleRemove}
            >
              <Icon.Trash2 />
              <span className="pd-l-5">Remove</span>
            </Button>
          )}

          <Button
            variant="brand-02"
            className="pd-x-20"
            onClick={handleContinue}
            disabled={removing || saving}
          >
            {saving ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>
                <span className="pd-r-5">Continue</span>
                <Icon.ArrowRight />
              </span>
            )}
          </Button>
        </div>
      </div>

      {showUploadModal && (
        <UploadModal closeModal={closeUploadModal} uppy={uppy} />
      )}

      <style jsx>{`
        .app-sticky-footer {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
        }
      `}</style>
    </div>
  );
}

export default SpaceContent;
