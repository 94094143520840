import api from '../api';
import { useSelector } from 'react-redux';
import * as Icon from 'react-feather';
import * as Sentry from '@sentry/nextjs';
import Avatar from './Avatar';
import { WizardAction, WizardSelector } from '../state/wizard';
import { AuthUserAction, AuthUserSelector } from '../state/authUser';
import { NotificationAction } from '../state/notification';
import { useActions } from './useActions';
import { logout } from '../api/auth';
import { usePluginHost } from './usePluginHost';
import { get, startCase } from 'lodash';
import { ADMIN_CP_URL, HELP_LINK, APP_VERSION } from './config';
import Cookie from 'js-cookie';

import Dropdown from 'react-bootstrap/Dropdown';

const getUserRole = (user) => startCase(get(user, 'role'));

const stepLabels = {
  home: 'Home',
  backdrop: 'Backdrop',
  new: 'New Backdrop',
  complete: 'Complete',
};

function AppHeader(props) {
  const authUser = useSelector(AuthUserSelector.getUser);

  const removeAuthUser = useActions(AuthUserAction.removeUser);
  const handleLogout = async () => {
    await logout();
    removeAuthUser();
  };

  const activeStep = useSelector(WizardSelector.getActiveStep);
  const previousStep = useSelector(WizardSelector.getPreviousStep);
  const goBack = useActions(WizardAction.goBack);

  const reload = () => {
    window.location = '/';
  };

  const { resetSettings } = usePluginHost();
  const factoryReset = async () => {
    await logout();
    await resetSettings();

    window.location = '/';
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    goBack();
  };

  const showNotification = useActions(NotificationAction.show);
  const handleEmailLoginLink = async (e) => {
    e.preventDefault();

    try {
      const deviceId = Cookie.get('deviceId');
      await api.service('device-tokens').create(
        {
          deviceId: deviceId,
        },
        {
          query: {
            sendEmail: true,
          },
        }
      );
      showNotification({
        title: 'Success',
        body: 'An email has been sent to your email address. Please check your inbox.',
      });
    } catch (ex) {
      showNotification({
        title: 'Error',
        body: 'An error occurred. Please try again.',
      });
      console.error(ex);
    }
  };

  const org = useSelector(AuthUserSelector.getOrganization);
  const handleReportIssues = () => {
    Sentry.withScope((scope) => {
      scope.setFingerprint(['report issues', Date.now()]);
      scope.setExtra('features', org.features);
      scope.setExtra('uploadConfig', org.uploadConfig);

      Sentry.captureMessage(`Issue reported by ${authUser.email}`);

      const event_id = Sentry.lastEventId();
      Sentry.showReportDialog({
        event_id,
        name: authUser.name,
        email: authUser.email,
      });
    });
  };

  return (
    <div className="app-header bg-gray-200">
      <div className="d-flex justify-content-between">
        <div className="mg-l-15">
          <span className="tx-primary" style={{ lineHeight: '48px' }}>
            {activeStep !== 'home' ? (
              <a href="#" onClick={handleGoBack}>
                <Icon.ChevronLeft size={16} /> {stepLabels[previousStep]}
              </a>
            ) : (
              <Icon.Home size={16} className="mg-l-5" />
            )}
          </span>
        </div>
        <div className="">
          <Dropdown className="dropdown-icon">
            <Dropdown.Toggle
              id="user-dropdown"
              variant="link"
              className="dropdown-link dropdown-trigger-custom"
            >
              <Avatar
                name={authUser?.name}
                email={authUser?.email}
                image={authUser?.avatar}
                size="sm"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="wd-200">
              <div className="pd-x-10 pd-t-10 pos-relative">
                <Avatar
                  name={authUser?.name}
                  email={authUser?.email}
                  image={authUser?.avatar}
                  size="lg"
                />
                <h6 className="tx-semibold mg-b-5 mg-t-20">{authUser?.name}</h6>
                <p className="tx-12 tx-color-03">{getUserRole(authUser)}</p>

                <div className="pos-absolute r-10 t-5 tx-12 tx-color-03">
                  v{APP_VERSION}
                </div>
              </div>

              <Dropdown.Divider />

              <Dropdown.Item
                eventKey="email-login-link"
                onClick={handleEmailLoginLink}
              >
                <Icon.Key size={16} />
                <span className="tx-13">Email Login Link</span>
              </Dropdown.Item>

              <Dropdown.Divider />

              <Dropdown.Item
                eventKey="admin-cp"
                href={ADMIN_CP_URL}
                target="_blank"
              >
                <Icon.ExternalLink size={16} />
                <span className="tx-13">Admin CP</span>
              </Dropdown.Item>

              <Dropdown.Item eventKey="help" href={HELP_LINK} target="_blank">
                <Icon.HelpCircle size={16} />
                <span className="tx-13">Help Center</span>
              </Dropdown.Item>

              <Dropdown.Item
                eventKey="report_issues"
                onClick={handleReportIssues}
              >
                <Icon.AlertCircle size={16} />
                <span className="tx-13">Report Issues...</span>
              </Dropdown.Item>

              <Dropdown.Divider />

              <Dropdown.Item eventKey="reload" onClick={reload}>
                <Icon.RefreshCcw size={16} />
                <span className="tx-13">Reload Add-In</span>
              </Dropdown.Item>

              <Dropdown.Item eventKey="reset" onClick={factoryReset}>
                <Icon.AlertTriangle size={16} />
                <span className="tx-13">Factory Reset</span>
              </Dropdown.Item>

              <Dropdown.Item eventKey="sign-out" onClick={handleLogout}>
                <Icon.LogOut size={16} />
                <span className="tx-13">Sign Out</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default AppHeader;
