import prettyBytes from 'filesize.js';
import { ContextMenuTrigger } from 'react-contextmenu';
import cx from 'classnames';

import * as Icon from 'react-feather';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Badge from 'react-bootstrap/Badge';

import { getFileClassnames, getIntegrationClassnames } from './helpers';

function DocumentItem(props) {
  const { file, editable, selected = true, onChange } = props;

  const { fileColorClassName, fileIconClassName } = getFileClassnames(file);

  // media type
  const mediaType = file.type;
  const { iconClassName, color } = getIntegrationClassnames(
    file.integrationType
  );

  // link provider
  const providerText = file.embedProviderName || file.embedProviderUrl;

  // handle click
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onChange && onChange(file._id);
  };

  return (
    <ContextMenuTrigger
      id="document_context_menu"
      collect={() => ({ file, editable })}
      holdToDisplay={-1}
    >
      <div className="pos-relative" onClick={handleClick}>
        <div
          className="custom-control custom-checkbox pos-absolute l-5 t-5"
          style={{ zIndex: 999 }}
          onClick={handleClick}
        >
          <input
            type="checkbox"
            checked={selected}
            className="custom-control-input"
            id={`checkbox_${file._id}`}
          />
          <label class="custom-control-label" htmlFor={`checkbox_${file._id}`}>
            &nbsp;
          </label>
        </div>
        <div
          id={`media_${file._id}`}
          className={cx('card card-file document-item', { selected })}
        >
          {file.thumbnailUrl ? (
            <div
              className="card-file-thumb"
              style={{
                backgroundImage: `url(${file.thumbnailUrl})`,
                backgroundSize: 'cover',
                filter: selected ? '' : 'grayscale(80%)',
              }}
            ></div>
          ) : (
            <div className={`card-file-thumb ${fileColorClassName}`}>
              <i className={`far ${fileIconClassName}`} />
            </div>
          )}

          <div className="card-body">
            <h6>
              <a className="link-02 tx-12" title={file.title}>
                {file.title}
              </a>
            </h6>
            {mediaType === 'file' && (
              <span className="tx-10 tx-gray-600">
                {prettyBytes(file.fileSize || 0)}
              </span>
            )}
            {mediaType === 'integration' && (
              <div>
                <i
                  className={`${iconClassName} tx-16`}
                  style={{ color: color, verticalAlign: 'middle' }}
                ></i>{' '}
                <span className="tx-10 tx-gray-600">
                  {file.integrationType}
                </span>
              </div>
            )}
            {mediaType === 'url' && (
              <div>
                <Icon.Globe size={13} color="#8392a5" />
                <span className="mg-l-5 tx-10 tx-gray-600">{providerText}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </ContextMenuTrigger>
  );
}

export default DocumentItem;
